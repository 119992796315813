<template>
  <div class="flex flex-row flex-wrap gap-8 px-8">
    <template v-for="note in notes">
      <v-hover
        :key="note.objectId"
        v-slot="{ hover }">
        <v-card
          max-width="250"
          :elevation="hover ? 8 : 2"
          >
          <v-card-title
            class="cursor-pointer hover:underline"
            @click.stop.prevent="$emit('select', note)">
            <span class="truncate">{{ note.title }}</span>
          </v-card-title>
          <v-card-text
            class="mb-0 pb-0"
            @click.stop.prevent="$emit('select', note)">
            <v-img
              src="https://picsum.photos/350/165?random"
              height="125"
              class="cursor-pointer"
              >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="flex flex-row items-center space-x-2 mt-3">
              <span class="caption">
                Updated {{ note.updatedAt | timeAgo }} ago
              </span>
              <v-spacer></v-spacer>
              <span class="caption border px-2 rounded-md">
                {{ note.type | capitalize }}
              </span>
            </div>
          </v-card-text>
          <v-card-text class="py-0 my-0">
            <v-chip-group row>
              <template v-for="(folder, index) in getAncestors(note)">
                <v-chip
                  x-small
                  :key="`${folder.name}-${index}`"
                  color="white"
                  class="px-0 mx-0">
                  <div class="flex flex-row shrink items-center p-0 m-0">
                    <v-icon size="16">mdi-chevron-right</v-icon>
                    <span>{{ folder.name | capitalize }}</span>
                  </div>
                </v-chip>
              </template>
            </v-chip-group>
          </v-card-text>
          <v-card-text class="py-0 my-0">
            <v-chip-group
              row
              >
              <template v-for="label in note.labels">
                <v-chip
                  :key="label.text"
                  x-small
                  :color="label.color"
                  close
                  @click:close="$emit('remove-label', note, label)"
                  >{{ label.text }}
                </v-chip>
              </template>
            </v-chip-group>
          </v-card-text>
          <v-card-actions
            v-if="hover">
            <template v-if="mode == 'trash'">
              <v-btn
                class="border-none"
                fab
                outlined
                x-small
                @click.stop.prevent="$emit('restore', note)"
                >
                <v-icon>
                  mdi-recycle-variant
                </v-icon>
              </v-btn>
              <v-btn
                class="border-none"
                fab
                outlined
                x-small
                @click.stop.prevent="$emit('alert-remove', note)"
                >
                <v-icon>
                  mdi-delete-alert-outline
                </v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                class="border-none"
                fab
                outlined
                x-small
                @click.stop.prevent="$emit('copy', note)"
                >
                <v-icon dark>
                  mdi-content-copy
                </v-icon>
              </v-btn>
              <v-btn
                class="border-none"
                fab
                outlined
                x-small
                @click.stop.prevent="$emit('move', note)"
                >
                <v-icon>
                  mdi-file-move-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="border-none"
                fab
                outlined
                x-small
                @click.stop.prevent="$emit('label', note)"
                >
                <v-icon>
                  mdi-label-multiple-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="border-none"
                fab
                outlined
                x-small
                @click.stop.prevent="$emit('share', note)"
                >
                <v-icon dark>
                  mdi-share-variant-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="border-none"
                fab
                outlined
                x-small
                @click.stop.prevent="$emit('pin', note)"
                >
                <v-icon v-if="$store.getters['notePinned'](note)" dark>
                  mdi-pin-off-outline
                </v-icon>
                <v-icon v-else>
                  mdi-pin-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="border-none"
                fab
                outlined
                x-small
                @click.stop.prevent="$emit('remove', note)">
                <v-icon dark>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </template>
          </v-card-actions>
          <v-card-actions v-else class="h-12"></v-card-actions>
        </v-card>
      </v-hover>
    </template>
  </div>
</template>

<script>

export default {
  name: "NotesGrid",

  props: {
    notes: {
      type: Array,
      default: () => {
        return []
      }
    },

    mode: {
      type: String,
      default: 'normal'
    }
  },

  data() {
    return {
      activeNote: null
    }
  },

  methods: {
    getAncestors(note) {
      if (!note.folder) {
        return [ { name: 'Uncategoried' } ]
      } else {
        return this.$store.getters['folders/ancestors'](note.folder)
      }
    }
  }
}
</script>

<style>

.v-slide-group__prev,
.v-slide-group__next {
  min-width: 0 !important;
  flex: none !important;
  border-radius: 100% !important;
  background: transparent !important;
}

</style>