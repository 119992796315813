<template>
  <v-menu
    v-model="showMenu"
    open-on-hover
    bottom
    offset-y
    :close-on-content-click="false"
    :min-width="480"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        icon
        outlined
        v-bind="attrs"
        v-on="on">
        <v-icon>mdi-menu-down-outline</v-icon>
      </v-btn>
    </template>
    <note-type-selector
      @close="close"
      v-model="selectedTypes"
      >
    </note-type-selector>
  </v-menu>
</template>

<script>
import NoteTypeSelector from './NoteTypeSelector.vue'

export default {
  name: 'NoteTypeSelectorMenu',

  props: {
    selectedTypes: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  components: {
    NoteTypeSelector
  },

  data() {
    return {
      showMenu: false
    }
  },

  methods: {
    close() {
      this.showMenu = false
    }
  }
}
</script>