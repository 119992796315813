<template>
  <v-list dense class="px-8">
    <template v-for="(item, index) in notes">
        <v-divider
          :key="`divider-${index}`"
          :inset="item.inset"
        ></v-divider>
        <v-list-item
          :key="index"
          @click="() => {}">
          <template v-slot:default>
            <v-hover
            v-slot="{ hover }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.title"
                  :class="[hover ? 'underline': '', 'cursor-pointer']"
                  @click.stop.prevent="$emit('select', item)"
                  >
                </v-list-item-title>
                <v-list-item-subtitle class="my-2"
                >
                  <v-img
                    src="https://picsum.photos/350/165?random"
                    height="125"
                    class="cursor-pointer"
                    @click.stop.prevent="$emit('select', item)"
                    >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span class="mx-1 border caption px-2 rounded-md">{{ item.type | capitalize }}</span>&middot;
                  <span>Updated {{ item.updatedAt | timeAgo }} ago</span>
                </v-list-item-subtitle>

                <v-list-item-subtitle>
                  <div class="flex flex-row w-full items-center">
                    <div class="flex flex-row shrink items-center text-xs text-gray-500 ml-2">
                      <template v-for="(folder, index) in getAncestors(item)">
                        <div
                          :key="`${folder.name}-${index}`"
                          class="flex flex-row shrink items-center space-x-1">
                          <v-icon size="16">mdi-chevron-right</v-icon>
                          <span>{{ folder.name | capitalize }}</span>
                        </div>
                      </template>
                    </div>
                    <v-spacer></v-spacer>
                    <v-chip-group row>
                      <template v-for="label in item.labels">
                        <v-chip
                          :key="label.text"
                          small
                          class="mx-1"
                          :color="label.color"
                          close
                          @click:close="$emit('remove-label', item, label)">
                          {{ label.text }}
                        </v-chip>
                      </template>
                    </v-chip-group>
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="hover">
                  <template v-if="mode == 'trash'">
                    <v-btn
                      class="border-none"
                      outlined
                      small
                      @click.stop.prevent="$emit('restore', item)"
                      >
                      <v-icon dark>
                        mdi-recycle-variant
                      </v-icon>
                      Recover
                    </v-btn>
                    <v-btn
                      class="border-none"
                      outlined
                      small
                      @click.stop.prevent="$emit('alert-remove', item)"
                      >
                      <v-icon dark>
                        mdi-delete-alert-outline
                      </v-icon>
                      Delete forever
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      class="border-none"
                      outlined
                      small
                      @click.stop.prevent="$emit('copy', item)"
                      >
                      <v-icon dark>
                        mdi-content-copy
                      </v-icon>
                      Clone
                    </v-btn>
                    <v-btn
                      class="border-none"
                      outlined
                      small
                      @click.stop.prevent="$emit('move', item)"
                      >
                      <v-icon dark>
                        mdi-file-move-outline
                      </v-icon>
                      Move to
                    </v-btn>
                    <v-btn
                      class="border-none"
                      outlined
                      small
                      @click.stop.prevent="$emit('label', item)"
                      >
                      <v-icon dark>
                        mdi-label-multiple-outline
                      </v-icon>
                      Add Label
                    </v-btn>
                    <v-btn
                      class="border-none"
                      outlined
                      small
                      @click.stop.prevent="$emit('share', item)"
                      >
                      <v-icon dark>
                        mdi-share-variant-outline
                      </v-icon>
                      Share it
                    </v-btn>
                    <v-btn
                      class="border-none"
                      outlined
                      x-small
                      @click.stop.prevent="$emit('pin', note)"
                      >
                      <template v-if="$store.getters['notePinned'](item)">
                        <v-icon dark>
                          mdi-pin-off-outline
                        </v-icon>
                        Unpin
                      </template>
                      <template v-else>
                        <v-icon>
                          mdi-pin-outline
                        </v-icon>
                        Pin
                      </template>
                    </v-btn>
                    <v-btn
                      class="border-none"
                      outlined
                      @click.stop.prevent="$emit('remove', item)">
                      <v-icon dark>
                        mdi-trash-can-outline
                      </v-icon>
                      Remove
                    </v-btn>
                  </template>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else
                  class="h-9">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-hover>
          </template>
        </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'NotesList',

  props: {
    notes: {
      type: Array,
      default: () => {
        return []
      }
    },

    mode: {
      type: String,
      default: 'normal'
    }
  },

  methods: {
    getAncestors(note) {
      if (!note.folder) {
        return [ { name: 'Uncategoried' } ]
      } else {
        return this.$store.getters['folders/ancestors'](note.folder)
      }
    }
  }
}
</script>