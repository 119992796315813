<template>
  <v-list dense class="bg-white">
    <v-subheader class="py-0 my-0 px-4 w-full flex">
      <span class="grow">Note types</span>
      <v-btn icon small @click.stop.prevent="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-subheader>
    <v-container class="pt-0 px-4 pb-8">
      <v-row>
        <v-col cols="6">
          <template v-for="(item, index) in noteTypes">
            <v-checkbox
              :key="index"
              dense
              :label="item.name"
              v-model="noteTypes[index].selected"
              v-if="index % 2 == 0"
              @change="updateSelection(item)"
              >
              <template v-slot:label>
                <div class="flex flex-row items-center space-x-2">
                  <v-icon>{{ item.icon }}</v-icon>
                  <span>{{ item.name }}</span>
                </div>
              </template>
            </v-checkbox>
          </template>
        </v-col>
        <v-col cols="6">
          <template v-for="(item, index) in noteTypes">
            <v-checkbox
              :key="index"
              :label="item.name"
              dense
              v-model="noteTypes[index].selected"
              v-if="index % 2 == 1"
              @change="updateSelection(item)"
              >
              <template v-slot:label>
                <div class="flex flex-row items-center space-x-2">
                  <v-icon>{{ item.icon }}</v-icon>
                  <span>{{ item.name }}</span>
                </div>
              </template>
            </v-checkbox>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-list>
</template>

<script>
import _ from 'lodash'
import NoteTypes from './NoteTypes'

export default {
  name: 'NoteTypeSelector',

  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      selectedTypes: this.value
    }
  },

  computed: {
    noteTypes() {
      return NoteTypes
    }
  },

  methods: {
    updateSelection(item) {
      let index = _.findIndex(this.selectedTypes, (x) => x.key == item.key)
      if (item.selected && index < 0) {
        this.selectedTypes.push(item)
      } 
      if (index > -1 && !item.selected) {
        this.selectedTypes.splice(index, 1)
      }
      this.$emit('input', this.selectedTypes)
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>