<template>
  <div class="flex flex-row space-x-2 shrink items-center">
    <template v-if="types.length > 0">
      <template v-if="types.length <= 2">
        <template v-for="type in types">
          <v-chip
            :key="type.name"
            small
            close
            @click:close="clickClose(type)"
            >
            {{ type.name }}
          </v-chip>
        </template>
      </template>
      <template v-else>
        <template v-for="index in [0, 1]">
          <v-chip
            :key="index"
            small
            close
            @click:close="removeSelectedLabel(types[index])"
            >
            {{ types[index].name }}
          </v-chip>
        </template>
        <v-menu
          :close-on-content-click="false"
          bottom
          offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text outlined small v-on="on">
              <span>{{ types.length - 2}} More...</span>
            </v-btn>
          </template>
          <div class="flex flex-col space-y-2 p-4">
            <template v-for="(type, index) in types">
              <template v-if="index > 1">
                <v-chip
                  :key="index"
                  small
                  close
                  @click:close="clickClose(type)"
                  >
                  {{ type.name }}
                </v-chip>
              </template>
            </template>
          </div>
        </v-menu>
      </template>
    </template>
    <template v-else>
      <span class="text-gray-500 text-xs">No type</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NoteTypeList',

  props: {
    types: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  methods: {
    clickClose(item) {
      this.$emit('click-close', item)
    }
  }
}
</script>